import React from "react";
import { Button } from "antd";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import _ from "lodash";
import { validEmail, validUrl } from "../../helpers";

export default function NavButton(props) {
  const isDisabled =
    !props.watchAll?.casinoName ||
    !props.watchAll?.owner ||
    !props.watchAll?.casinoDomain ||
    !props.watchAll?.yearEstablished ||
    !props.previewSource ||
    _.isEmpty(props.watchAll.casinoTypes) ||
    _.isEmpty(props.watchAll.depositMethods) ||
    _.isEmpty(props.watchAll.countries) ||
    _.isEmpty(props.watchAll.restrictedCountries) ||
    _.isEmpty(props.watchAll.languages) ||
    _.isEmpty(props.watchAll.licenses) ||
    _.isEmpty(props.watchAll.software) ||
    _.isEmpty(props.watchAll.supportTypes) ||
    !validEmail(props.watchAll.contactEmail) ||
    _.isEmpty(props.watchAll.companyLegalName) ||
    _.isEmpty(props.watchAll.companyAddress) ||
    _.isEmpty(props.watchAll.companyRegNumber) ||
    _.isEmpty(props.watchAll.contactName) ||
    !props.watchAll.top3Usp
      ?.reduce((acc, item) => {
        if (item?.usp) {
          acc.push(item?.usp);
        }
        return acc;
      }, [])
      .join?.(", ") ||
    !props.recaptchaKey ||
    props.isLoadingToAllan;

  console.log("isDisabled", isDisabled);
  console.log("props.recaptchaKey", props.recaptchaKey);

  return (
    <div className="sub-container nav-container">
      <div className="button-wrapper">
        {props.current > 0 && (
          <Button className="nav-button" onClick={props.prev}>
            <FaAngleLeft className="nav-arrow" /> Previous
          </Button>
        )}
        {props.current < 6 && (
          <Button className="nav-button" onClick={props.next}>
            Next <FaAngleRight className="nav-arrow" />
          </Button>
        )}
        {props.current === 6 && (
          <Button
            htmlType="submit"
            className="nav-button"
            disabled={isDisabled}
          >
            {props.isLoadingToAllan ? "Loading..." : "Submit"}
          </Button>
        )}
      </div>
    </div>
  );
}
